import React, { useState } from 'react';
import Modal from 'react-modal';
import SaveToGooglePay from "@google-pay/save-button-react";
import "./confirmModal.css";
import DownloadIcon from "../views/ViewReceipt/download-svgrepo-com.svg";
import Translator from "../languages/Translator";
import GoogleWalletIcon from "../views/ViewReceipt/192px.svg";


Modal.setAppElement('#root');

const ConfirmModal = ({ isOpen, onRequestClose, fileUrl, jwt }) => {
    const [isAndroid, setIsAndroid] = useState(/Android/i.test(navigator.userAgent));

    const downloadPDF = () => {
        window.open(fileUrl);
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            borderRadius: '10px',
            border: "1px solid rgb(204 204 204 / 0%) !important",
            padding: '20px',
            width: '100%',
            flexDirection: "row",
            maxWidth: "max-content",
            background: 'rgb(255 255 255 / 0%)',
            textAlign: 'center',
            display: isOpen ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
        },
    };

    const buttonStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "20px",
        marginBottom: isAndroid ? "5px" : undefined,
        backgroundColor: "black",
        color: "white",
        fontFamily: "Open Sans",
        fontWeight: "bold",
        gap: isAndroid ? "5px" : undefined,
        padding: isAndroid ? "7px" : undefined,
        width: isAndroid ? "200px" : "190px",
        height: isAndroid ? "35px" : undefined,
        cursor: "pointer",
    }

    const buttonContainerStyle = {
        flexGrow: "1",
        display: "flex",
        justifyContent: "center",
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Conferma"
            style={customStyles}
        >
            <div className="modal-content">
                <h2 className="modal-title">{Translator("app.receipt.modal.title", "Warning!")}</h2>
                <p className="modal-message">{Translator("app.receipt.modal.body", "The signed PDF is only available through the download or share functions")}</p>
                <div className="modal-buttons" style={{ flexDirection: isAndroid ? "column" : "row" }}>
                    <div className="modal-single-button" style={buttonContainerStyle}>
                        <button onClick={downloadPDF} style={buttonStyle}>
                            <img src={DownloadIcon} style={{ height: "17px", width: "25px", alignSelf: "center" }} />
                            {Translator("app.receipt.pdf.button", "Download PDF")}
                        </button>
                    </div>
                    <div className="modal-single-button" style={buttonContainerStyle}>
                        <a href={`${process.env.REACT_APP_GOOGLE_WALLET_SAVE_URL}/${jwt}`} className="googleWalletLink" target="blank" placeholder="Add To Google Wallet">
                            <button className="addgooglewalletbutton" alt="googlePassButton" >
                                <img src={GoogleWalletIcon} style={{ height: "26px", width: "37px" }} />
                                <span className="googlewalletspan">{Translator("app.receipt.add.wallet", "Add to Google Wallet")}</span>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
