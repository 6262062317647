import React, { useEffect } from "react";
import TranslateProvider from "./languages/TranslateProvider";
import Routes from "./routes";
import "./App.css";
import logo from "./labware_new_logo.png";


export default function App() {
	useEffect(() => {
		document.title = `${process.env.REACT_APP_SITE_TITLE}`;
	}, []);

	return (
		<div className="App-canvas">
			<div className="App">
				<TranslateProvider>
					<Routes />
				</TranslateProvider>
				<footer>
					<div className="footer-center">
						Powered by <img src={logo} className="App-logo" alt="logo" />
					</div>
				</footer>
			</div>
		</div>
	);
}
