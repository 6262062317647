import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Translator from "../../languages/Translator";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
// import SaveToGooglePay from "@google-pay/save-button-react";
import addToAppleWalletIT from "./IT_Add_to_Apple_Wallet_RGB_101821.svg";
import addToAppleWalletUS from "./US-UK_Add_to_Apple_Wallet_RGB_101421.svg";
import addToAppleWalletDE from "./DE_Add_to_Apple_Wallet_RGB_101421.svg";
import addToAppleWalletFR from "./FR_Add_to_Apple_Wallet_RGB_102921.svg";
import GoogleWalletIcon from "./192px.svg";
import ConfirmModal from "../../components/ConfirmModal";
import "../../App.css";


export default function ViewReceipt() {
    let { receipt } = useParams();
    const [filePDF, setFilePDF] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [isIOS, setIsIOS] = useState(/Mac|iPhone|iPad|iPod/i.test(navigator.userAgent));
    const [isAndroid, setIsAndroid] = useState(/Android/i.test(navigator.userAgent));
    const [isItalian, setIsItalian] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [jwt, setJwt] = useState(null);
    const [iosVersion, setIosVersion] = useState(null);
    const [isChromeIOS, setIsChromeIOS] = useState(/CriOS/i.test(navigator.userAgent));
    const [isIosVersionClicked, setIsIosVersionClicked] = useState(false);
    const [deviceLanguage, setDeviceLanguage] = useState(navigator.language.slice(0, 2));
    const AppleWalletOrdersRequirements = 17; //ios version needed for applewallet orders to work properly

    useEffect(() => {
        GetReceiptPDF();
        getUserCountry();
        getJwtObject();
        iOSversion();
    }, []);

    const GetReceiptPDF = () => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/direceipt/pdf?urlUID=${receipt}`;
        const params = { method: "GET", responseType: "blob" };

        fetch(url, params)
            .then((response) => {
                if (response.ok) {
                    return response.blob();
                }
                return Promise.reject(response);
            })
            .then((response) => {
                setFilePDF(response);
                setFileUrl(URL.createObjectURL(response));
            })
            .catch(() => {
                alert("Impossibile aprire il file");
            });
    };

    const getJwtObject = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/jwt-signing/${receipt}`);
            const data = await response.json();
            setJwt(data.message);
        } catch (error) {
            console.log("Errore durante il ricevimento del JWT", error);
        }
    };

    const getAppleWalletPass = () => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/apple-order/${receipt}`;
        const params = { method: "GET" };

        fetch(url, params)
            .then((response) => {
                if (response.ok) {
                    return response.blob();
                }
                return Promise.reject(response);
            })
            .then((response) => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(response);
                link.setAttribute('download', 'order.order');
                document.body.appendChild(link);
                link.click();
            })
            .catch(() => {
                alert("Impossibile aprire il file");
            });
    };

    const shareReceipt = () => {
        try {
            if (navigator.canShare) {
                if (filePDF instanceof Blob) {
                    let date = new Date().toJSON().slice(0, 10);
                    const pdfFile = new File([filePDF], 'receipt_' + date + '.pdf', { type: 'application/pdf' });
                    navigator.share({ files: [pdfFile] })
                        .then(() => console.log('Condivisione riuscita'))
                        .catch((error) => console.log('Errore durante la condivisione', error));
                } else {
                    console.log('File PDF non disponibile o non valido');
                }
            }
        } catch (error) {
            console.log("Condivisione non supportata", error);
        }
    };

    const getUserCountry = () => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/get-country/${receipt}`;
        const params = { method: "GET" };

        fetch(url, params)
            .then((response) => {
                if (response.ok) {
                    return response.text();
                }
                return Promise.reject(response);
            })
            .then((data) => {
                if (data === "IT") {
                    setIsItalian(true);
                } else {
                    setIsItalian(false);
                }
            })
            .catch((error) => {
                console.log("Errore durante il ricevimento del paese", error);
            });
    }

    function iOSversion() {
        const userAgent = navigator.userAgent;
        if (isIOS) {
            const match = userAgent.match(/OS (\d+)[._](\d+)[._]?(\d+)?/);
            if (match) {
                const iosVersionArray = [parseInt(match[1], 10), parseInt(match[2], 10), parseInt(match[3] || 0, 10)];
                setIosVersion(iosVersionArray[0]);
            }
        } else {
            return null
        }
    }

    const getAppleWalletSVG = (language) => {
        switch (language) {
            case 'it':
                return addToAppleWalletIT;
            case 'en':
                return addToAppleWalletUS;
            case 'de':
                return addToAppleWalletDE;
            case 'fr':
                return addToAppleWalletFR;
            default:
                return addToAppleWalletUS;
        }
    };

    const appleWalletImage = getAppleWalletSVG(deviceLanguage);


    const handleCloseButtonIosVersion = () => {
        setIsIosVersionClicked(false);
    }

    const handleOpenButtonIosVersion = () => {
        setIsIosVersionClicked(true);
    }

    const downloadPdf = () => {
        window.open(fileUrl);
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handleGoogleWalletModal = () => {
        setIsModalOpen(true);
    };

    const redirectToSafari = (url) => {
        window.location.href = `x-safari-${url}`;
    }

    const handleAppleWalletClick = () => {
        if (isChromeIOS && iosVersion >= AppleWalletOrdersRequirements) {
            redirectToSafari(`${process.env.REACT_APP_API_BASE_URL}/apple-order/${receipt}`);
        } else {
            if (iosVersion < AppleWalletOrdersRequirements) {
                handleOpenButtonIosVersion();
            } else {
                getAppleWalletPass();
            }
        }
    };

    return (
        <>
            <div className="Receipt-Buttons">
                <div className={fileUrl ? "Receipt-Download-PDF" : "Receipt-Download-PDF-disabled"} onClick={fileUrl ? downloadPdf : null} style={{ lineHeight: fileUrl ? deviceLanguage === 'de' || deviceLanguage === 'fr' ? '16px' : null : null, fontSize: fileUrl ? deviceLanguage === 'de' || deviceLanguage === 'fr' ? '16px' : null : null }}>
                    {Translator("app.receipt.download.pdf", "Download PDF")}
                </div>
                <div className={filePDF ? "share-receipt" : "share-receipt-disabled"} id="share" onClick={filePDF ? shareReceipt : null}>
                    {Translator("app.receipt.share.pdf", "Share")}
                </div>
            </div>

            {isIOS && (
                <div>
                    <img src={appleWalletImage} alt="applePassButton" onClick={handleAppleWalletClick} style={{ cursor: "pointer", height: "45px", width: "170px" }} />
                </div>
            )}

            {isIOS ? (
                <div className="modalOverlay" style={{ display: isIosVersionClicked ? "flex" : "none" }}>
                    <div className="iosVersionDiv">
                        <span className="titleSpan">{Translator("app.receipt.ios.title", "Warning!")}</span>
                        <span className="bodySpan">{Translator("app.receipt.ios.body", "Your IOS version doesn't meet the requirements for Apples's Wallet Orders")}</span>
                        <button className="iosVersionButton" onClick={handleCloseButtonIosVersion}>
                            {Translator("app.receipt.ios.confirm", "Confirm")}
                        </button>
                    </div>
                </div>
            ) : null}

            {isItalian && isAndroid &&
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <button className="addgooglewalletbutton" alt="googlePassButton" onClick={handleGoogleWalletModal}>
                        <img src={GoogleWalletIcon} style={{ height: "26px", width: "37px" }} />
                        <span className="googlewalletspan">{Translator("app.receipt.add.wallet", "Add to Google Wallet")}</span>
                    </button>
                </div>
            }

            {!isItalian && jwt && isAndroid &&
                // <SaveToGooglePay
                //     height="small"
                //     size="small"
                //     textsize="small"
                //     theme="dark"
                //     jwt={jwt}
                // />
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <a href={`${process.env.REACT_APP_GOOGLE_WALLET_SAVE_URL}/${jwt}`} className="googleWalletLink" target="blank" placeholder="Add To Google Wallet">
                        <button className="addgooglewalletbutton" alt="googlePassButton" >
                            <img src={GoogleWalletIcon} style={{ height: "26px", width: "37px" }} />
                            <span className="googlewalletspan">{Translator("app.receipt.add.wallet", "Add to Google Wallet")}</span>
                        </button>
                    </a>
                </div>
            }

            {fileUrl ? (
                <div className="pdf-container">
                    <Document file={filePDF} className="pdf" onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} />
                    </Document>
                    <div className="pagination">
                        <button disabled={pageNumber === 1} onClick={() => setPageNumber(() => pageNumber - 1)}>
                            {Translator("app.receipt.prev_page", "Prev page")}
                        </button>
                        <button disabled={pageNumber === numPages} onClick={() => setPageNumber(() => pageNumber + 1)}>
                            {Translator("app.receipt.next_page", "Next page")}
                        </button>
                    </div>
                    <p>
                        {Translator("app.receipt.page", "Page")} {pageNumber} {Translator("app.receipt.of", "of")} {numPages}
                    </p>
                </div>
            ) : (
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            )}

            {jwt &&
                <ConfirmModal
                    isOpen={isModalOpen}
                    onRequestClose={() => setIsModalOpen(false)}
                    fileUrl={fileUrl}
                    jwt={jwt}
                />
            }
        </>
    );
}
